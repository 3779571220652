import Header from "./Components/UI/Header/Header";
import Main from "./Components/UI/Main/Main";
import Footer from "./Components/UI/Footer/Footer";
import { useMediaQuery } from 'react-responsive'
import MobileHeader from "./Components/MobileUI/Header/MobileHeader";
import MobileMain from "./Components/MobileUI/Main/MobileMain";
import FooterMobile from "./Components/MobileUI/Footer/FooterMobile";


function App() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const Desktop = () => {
      return (
          <div id={'headerID'}>
              <Header/>
              <Main/>
              <a href='https://dskarkas.ru/pricelist.doc' download className='d-flex justify-content-center align-items-center rounded-circle text-white text-decoration-none'
                   style={{position: 'fixed', bottom: 10, left: 10, width: "100px", height: "100px", background: "#E30613"}}>
                  Прайс лист
              </a>
              <Footer/>
          </div>
      )
    }

    const Mobile = () => {
        return (
            <div id={"headerID"}>
                <MobileHeader/>
                <MobileMain/>
                <a href='https://dskarkas.ru/pricelist.doc' download className='d-flex justify-content-center align-items-center rounded-circle text-white text-decoration-none'
                     style={{position: 'fixed', bottom: 10, right: 10, width: "80px", height: "80px", zIndex:"12", background: "#E30613"}}>
                    Прайс
                </a>
                <FooterMobile/>
            </div>
        )
    }

    return (
        isTabletOrMobile ? <Mobile/> : <Desktop/>
    );
}

export default App;
