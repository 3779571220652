import React from 'react';

const Main = () => {
    return (
        <div>
            <img width={"100%"} src={"./image.svg"}/>
            <div className='px-5 mt-2 mb-5'>
                <AboutCompany/>
                <Projects/>
                {/*<Catalogue/>*/}
                {/*<Advantages/>*/}
            </div>
            <div id='contacts' className='px-3 position-relative'>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A7750061f6dfce84d58a62fb67b2ead11647bdd008e5f038ff824d6a8826dc1f1&amp;source=constructor"
                    width="100%" height="720" frameBorder="0"></iframe>
                <div className='position-absolute bg-white p-3'
                     style={{left: "60%", top: "20%", zIndex: '11', maxWidth: "408px"}}>
                    <h2 className='fw-bold'>Контакты</h2>
                    <div>
                        <div className='text-secondary'>Адрес</div>
                        <div className='fw-bold'>666021, Иркутская Область,р-н Шелеховский, ул Заводская (промышленная
                            Зона Тер), зд. 1
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className='text-secondary'>Отдел сбыта</div>
                            <div>+7 (8395) 248-87-41</div>
                        </div>
                        <div>
                            <div className='text-secondary'>Приемная</div>
                            <div>+7 (8352) 50-60-36</div>
                        </div>
                        <div>
                            <div className='text-secondary'>E-mail</div>
                            <div>office@dskarkas.ru</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;

const AboutCompany = () => {
    return (
        <div id='aboutCompany' className='mt-5' style={{margin: "0 auto", maxWidth: '1227px'}}>
            <h2 className='fw-bold'>О компании</h2>
            <div className='d-flex justify-content-between'>
                <img width={364} height={295} src={'./svarshik.svg'}/>
                <img width={840} height={295} src={'./zavod.svg'}/>
            </div>
            <h3 className='fw-bold mt-5'>ИРКУТСКИЙ ДОМОСТРОИТЕЛЬНЫЙ КОМБИНАТ «ИДСК»</h3>
            <div className='fw-bold'>Лидер в производстве железобетонных изделий, трехслойных железных стеновых
                панелей,бетонных сваях. Продукция производится из тяжелого армированного бетона разных марок.
            </div>
            <div className='mt-2'>В конце 2014 года началось строительство Иркутского ДСК мощностью 100 тыс. м2 (по
                площади перекрытий) сборных железобетонных зданий. Для строительства ДСК был выбран участок с удобным
                месторасположением рядом с федеральной трассой М53 на пересечении транспортных магистралей, соединяющих
                гг. Иркутск, Ангарск, Улан‐Удэ, в трех километрах от г. Шелехов.
            </div>
            {/*<button className='btn bg-danger text-white mt-1'>Подробнее</button>*/}
        </div>
    )
}


const Catalogue = () => {
    return (
        <div id={'priceList'} style={{margin: "0 auto", maxWidth: '1227px'}} className="mt-5">
            <h2 className='fw-bold'>Прайс-лист</h2>
            <div className='p-3'>
                <img src='./catalogue.svg'/>
                <div className="d-flex justify-content-between align-items-center">
                    Каталог ЖБИ
                </div>
            </div>
        </div>
    )
}


const Projects = () => {
    return (
        <div id={'projects'} style={{margin: "0 auto", maxWidth: '1227px'}} className="mt-5">
            <h2 className='fw-bold'>Наши проекты</h2>

            <div className='d-flex justify-content-between'>
                <div id="carouselExampleControls" className="carousel slide" style={{width: "600px"}}
                     data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./botanica/1.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./botanica/2.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./botanica/3.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./botanica/4.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./botanica/5.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon btn btn-dark opacity-75" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon btn btn-dark opacity-75" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

                <div id="carouselExampleControls1" className="carousel slide" style={{width: "600px"}}
                     data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./aviator/1.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./aviator/2.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./aviator/3.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./aviator/4.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls1"
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon btn btn-dark opacity-75" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls1"
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon btn btn-dark opacity-75" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div className={'w-100 d-flex justify-content-center mt-3'}>
                <div id="carouselExampleControls2" className="carousel slide" style={{width: "600px"}}
                     data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./pobeda/1.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="./pobeda/2.png" width={875} className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2"
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon btn btn-dark opacity-75" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2"
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon btn btn-dark opacity-75" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

        </div>
    )
}


const Advantages = () => {
    return (
        <div style={{margin: "0 auto", maxWidth: '1227px'}} className="mt-5">
            <h2 className='fw-bold'>Наши преимущества</h2>
            <div className='d-flex justify-content-between align-items-center p-5'>
                <div className="w-25" style={{maxWidth: "185px"}}>
                    <div className='d-flex justify-content-center'>
                        <img width={64} height={64} src={"./stars.svg"}/>
                    </div>
                    <div className='d-flex justify-content-center'>Опыт 10 лет</div>
                </div>
                <div className="w-25" style={{maxWidth: "185px"}}>
                    <div className='d-flex justify-content-center'>
                        <img width={64} height={64} src={"./ThumbUp.svg"}/>
                    </div>
                    <div className=''>
                        <div className='d-flex justify-content-center'>Высочайшее</div>
                        <div className='d-flex justify-content-center'>качество продукции</div>
                    </div>
                </div>
                <div className="w-25" style={{maxWidth: "185px"}}>
                    <div className='d-flex justify-content-center'>
                        <img width={64} height={64} src={"./Bricks.svg"}/>
                    </div>
                    <div>
                        <div className='d-flex justify-content-center'>Производим более</div>
                        <div className='d-flex justify-content-center'>40 000 продукции</div>
                    </div>
                </div>
                <div className="w-25" style={{maxWidth: "185px"}}>
                    <div className='d-flex justify-content-center'>
                        <img width={64} height={64} src={"./worker.svg"}/>
                    </div>
                    <div>
                        <div className='d-flex justify-content-center'>Квалифицированные</div>
                        <div className='d-flex justify-content-center'>сотрудники</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
