import React from 'react';
const Footer = () => {
    return (
        <div style={{background: "#21262B"}}>
            <div className='d-flex justify-content-between py-5' style={{margin: "0 auto", maxWidth:'1227px'}}>
                <Logo/>
                <Links/>
                <Numbers/>
                <Address/>
            </div>
            <div className='d-flex justify-content-between align-items-center text-secondary py-2 border-top border-secondary ' style={{margin: "0 auto", maxWidth:'1227px'}}>
                <div>2023 © АО "ИДСК"</div>
                <div>Создание сайта – Ara</div>
            </div>
        </div>
    );
};

export default Footer;



const Logo = () => {
   return (
       <div className='d-flex justify-content-between '>
           <div>
               <img src={'./logo1.svg'}/>
           </div>
           <div className='mx-2 align-items-center'>
               <div className='fs-5 fw-bold text-white'>АО "ИДСК"</div>
               <div className='fs-6 text-white'>Производство железобетонных изделий</div>
           </div>
       </div>
   )
}

const Links = () => {
  return (
      <div>
          <div className="text-white">
              <a className='text-decoration-none text-white' href="#headerID">Главная</a>
          </div>
          <div className="text-white mt-2">
              <a className='text-decoration-none text-white' href="#aboutCompany">Компания</a>
          </div>
          <div className="text-white mt-2">
              <a className='text-decoration-none text-white' href="#projects">Наши проекты</a>
          </div>
          <div className="text-white mt-2">
              <a className='text-decoration-none text-white' href="#priceList">Прайс-лист</a>
          </div>
          <div className="text-white mt-2">
              <a className='text-decoration-none text-white' href="#vacancy">Вакансии</a>
          </div>
          <div className="text-white mt-2">
              <a className='text-decoration-none text-white' href="#contacts">Контакты</a>
          </div>
      </div>
  )
}

const Numbers = () => {
  return (
      <div>
          <div>
              <div className='text-secondary'>Отдел сбыта</div>
              <div className="text-white">+7(8352)50-63-04</div>
          </div>
          <div className='mt-2'>
              <div className='text-secondary'>Отдел снабжения</div>
              <div className="text-white">+7(8352)50-63-72</div>
          </div>
      </div>
  )
}

const Address = () => {
  return (
      <div>
          <div>
              <div className='text-secondary'>Адрес</div>
              <div className='text-white'>666021, Иркутская Область, р-н Шелеховский,</div>
              <div className='text-white'> ул Заводская (промышленная Зона Тер), зд. 1</div>
          </div>

          <div className='mt-2'>
              <div className='text-secondary'>E-mail</div>
              <div className='text-white'>office@dskarkas.ru</div>
          </div>
      </div>
  )
}