import React from 'react';

const FooterMobile = () => {
    
    const FooterHeader = () => {
      return (
          <div className={'row'}>
              <div className={'col-sm-12 d-flex align-items-center mt-3'}>
                  <img height={60} src={'./logo1.svg'}/>
                  <div className={'d-md-flex'}>
                      <div className='fs-5 fw-bold text-white'>АО "ИДСК"</div>
                      <div className='fs-6  text-white'>Производство железобетонных изделий</div>
                  </div>
              </div>
          </div>
      )
    }
    
    const FooterLinks = () => {
      return (
          <div className={'row mt-2'}>
              <div className={'col-6'}>
                  <div className="text-white">
                      <a className='text-decoration-none text-white' href="#headerID">Главная</a>
                  </div>
                  <div className="text-white mt-2">
                      <a className='text-decoration-none text-white' href="#aboutCompany">Компания</a>
                  </div>
                  <div className="text-white mt-2">
                      <a className='text-decoration-none text-white' href="#projects">Наши проекты</a>
                  </div>
                  <div className="text-white mt-2">
                      <a className='text-decoration-none text-white' href="#priceList">Прайс-лист</a>
                  </div>
                  <div className="text-white mt-2">
                      <a className='text-decoration-none text-white' href="#vacancy">Вакансии</a>
                  </div>
                  <div className="text-white mt-2">
                      <a className='text-decoration-none text-white' href="#contacts">Контакты</a>
                  </div>
              </div>
              <div className={'col-6'}>
                  {/*<div>*/}
                  {/*    <div className='text-secondary'>Отдел сбыта</div>*/}
                  {/*    <div className="text-white">+7(8352)50-63-04</div>*/}
                  {/*</div>*/}
                  {/*<div className='mt-2'>*/}
                  {/*    <div className='text-secondary'>Отдел снабжения</div>*/}
                  {/*    <div className="text-white">+7(8352)50-63-72</div>*/}
                  {/*</div>*/}
                  {/*<div>*/}
                  {/*    <div className='text-secondary'>Адрес</div>*/}
                  {/*    <div className='text-white'>666021, Иркутская Область, р-н Шелеховский,</div>*/}
                  {/*    <div className='text-white'> ул Заводская, зд. 1</div>*/}
                  {/*</div>*/}

                  {/*<div className='mt-2'>*/}
                  {/*    <div className='text-secondary'>E-mail</div>*/}
                  {/*    <div className='text-white'>office@dskarkas.ru</div>*/}
                  {/*</div>*/}
              </div>
          </div>
      )
    }
    
    
    
    
    return (
        <div className={'container-fluid'} style={{background: "#21262B"}}>
            <FooterHeader/>
            <FooterLinks/>
        </div>
    );
};

export default FooterMobile;