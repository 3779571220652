import React from 'react';
import classes from "./Header.module.css"

const Header = () => {
    return (
        <div>
            <HeaderTop/>
            <HeaderInfo/>
            <HeaderMenu/>
        </div>
    );
};

export default Header;


const HeaderTop = () => {
    return (
        <div className='d-flex justify-content-between' style={{margin: "0 auto", maxWidth:'1227px'}}>
            <div>
                666021, Иркутская Область, р-н Шелеховский, ул Заводская, зд. 1
            </div>
            <div className='d-flex justify-content-between'>
                <div>
                    <span className='mx-1 text-secondary'>Режим работы</span>
                    <span className='fw-bold'>08:00 - 17:00</span>
                </div>
                <div className='mx-3'>
                    <span className='mx-1 text-secondary'>E-mail</span>
                    <span className='fw-bold'>office@dskarkas.ru</span>
                </div>
            </div>
        </div>
    )
}

const HeaderInfo = () => {
  return (
      <div className='d-flex justify-content-between' style={{margin: "0 auto", maxWidth:'1227px'}}>
          <div className='d-flex justify-content-between align-items-center '>
              <div>
                  <img width={110} height={99} src={'./logo.svg'}/>
              </div>
              <div>
                  <div className='fs-5 fw-bold'>АО "ИДСК"</div>
                  <div className='fs-6'>Производство железобетонных изделий</div>
              </div>
          </div>
          <div className='d-flex justify-content-between align-items-center w-50'>
              <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'>
                      <img width={24} height={24} src={'./phone.svg'}/>
                  </div>
                  <div className='mx-3'>
                      <div className='text-secondary'>Основной номер</div>
                      <div className='fw-bold text-danger'>+7(8395)248-87-41</div>
                  </div>
                  <div>
                      <div className='text-secondary'>Кадры</div>
                      <div className='fw-bold text-danger'>+7(8352)50-60-36</div>
                  </div>
              </div>
              <a href='https://dskarkas.ru/pricelist.doc' download className='btn py-2 text-white px-1' style={{background: "#E30613"}}>Прайс-лист ЖБИ</a>
          </div>
      </div>
  )
}

const HeaderMenu = () => {
    return (
        <div className='border-top bg-opacity-25'>
            <div className='d-flex justify-content-between align-items-stretch' style={{margin: "0 auto", maxWidth:'1227px'}}>
                <div className={[classes.link,'py-2','px-1'].join(" ")}>
                    <a className='text-decoration-none text-black' href='#headerID'>Главная</a>
                </div>
                <div className={[classes.link,'py-2','px-1'].join(" ")}>
                    <a className='text-decoration-none text-black' href='#aboutCompany'>Компания</a>
                </div>
                <div className={[classes.link,'py-2','px-1'].join(" ")}>
                    <a className='text-decoration-none text-black' href='#projects'>Наши проекты</a>
                </div>
                <div className={[classes.link,'py-2','px-1'].join(" ")}>
                    <a className='text-decoration-none text-black' href='#vacancy'>Вакансии</a>
                </div>
                <div className={[classes.link,'py-2','px-1'].join(" ")}>
                    <a className='text-decoration-none text-black'   href='#contacts'>Контакты</a>
                </div>
            </div>
        </div>
    )
}