import React from 'react';

const MobileHeader = () => {
    return (
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand d-flex align-items-center" href="#">
                        <img style={{background: "none"}} height={70} src={'./logo1.svg'}/>
                        <div className={'ms-2'}>
                            <div className='fs-5 fw-bold'>АО "ИДСК"</div>
                            <div className='fs-6'>Производство</div>
                            <div className='fs-6'>железобетонных</div>
                            <div className='fs-6'>изделий</div>
                        </div>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" href="#headerID">Главная</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#aboutCompany">Компания</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#projects">Наши проекты</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#vacancy">Вакансии</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contacts">Контакты</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Прайс-лист</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    );
};

export default MobileHeader;